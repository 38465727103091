body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.login-card{
  /* background-color : rgba(3, 29, 93 ); */
  height : 65vh;
  width : 30vw;
  /* margin-top : 100px;
  margin-left : 200px; */
  position : absolute;
  left : 500px;
  top : 130px;
  /* border : 5px solid yellow; */
}


.sidebar {
  margin: 0;
  padding: 0;
  width : 250px;
  background-color: white;
  border-right :2px  solid #02BBB7;
  position: fixed;
  height : 100%;
  overflow: auto;
}
.offcanvas-body{
  margin: 0;
  padding: 0;
  width: 250px;
  background-color: white;
  position: fixed;
  height : 100%;
  overflow: auto;
  transition :3s
}
.offcanvas-body a{
  display: block;
  color: black;
  padding: 12px;
  text-decoration: none;
  margin-top :10px;
  margin-left :20px
}
.offcanvas-body a.active {
  color: #02BBB7;
  font-weight :bold;
  border-radius :24px 0px 0px 24px;
}
.offcanvas-body a:hover:not(.active) {
  //background-color: #34C651;
  color: #02BBB7;
  font-weight :bold;
  border-radius :24px 0px 0px 24px;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}
.drawer{
  display :none
}
/* Sidebar links */
.sidebar a {
  display: block;
  color: black;
  padding: 12px;
  text-decoration: none;
  margin-top :10px;
  margin-left :20px
}

/* Active/current link */
.sidebar a.active {
  background-color: #02BBB7;
  color: white;
  font-weight :bold;
  border-radius :24px 0px 0px 24px;
}

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
  //background-color: #34C651;
  color: #02BBB7;
  font-weight :bold;
  border-radius :24px 0px 0px 24px;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 800px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    display :none
  }
  .sidebar a {
    float : left;
    margin-left :10px
    /* border-radius :24px ; */
  }
  .sidebar a.active {
    /* float : left; */
    border-radius :24px ;
  }
  .sidebar a:hover:not(.active) {
    
    border-radius :24px ;
  }
  div.content {margin-left: 0;}
  .drawer{
    display :block
  }
}

.card-dash{
  background :white;
  width :300px;
  border-radius :4px;
  display :inline-block;
  flex-direction :column;
  margin-left :0px;
  justify-content :center;
  transition: transform 0.8s ease;
}

.physio-details{
  margin-top :30px;
  
  float: left
}

.physio-details img{
  width:350px;
  height:220px
 }

 .physio-details-1{
  margin-left :80px
}

.form-input{
  width:100%;
  height:45px;
  outline:none;
  border-radius:10px;
  border:1px solid black
}

.submit-btn{
  width:100%;
  height:45px;
  border:none;
  background:green;
  border-radius:10px;
  margin-top:15px
}